<template>
  <b-row>
    <b-col cols="12">
      <section id="apercu2">
        <b-card
          no-body
        >
          <b-card-body>
            <b-table class="mt-2" responsive striped hover :fields="fields" :items="campagnes" small>
              <template #cell(status)="data">
                <b-badge
                  v-if="data.item.status == 'Campagne en cours'"
                  pill
                  :variant="`light-success`"
                  class="text-capitalize"
                >
                  <span>{{ data.item.status }}</span>
                </b-badge>
                <b-badge
                  v-else-if="data.item.status == 'En attente de lancement'"
                  pill
                  :variant="`light-warning`"
                  class="text-capitalize"
                >
                  <span>{{ data.item.status }}</span>
                </b-badge>
                <b-badge
                  v-else-if="data.item.status == 'En attente de désactivation'"
                  pill
                  :variant="`light-warning`"
                  class="text-capitalize"
                >
                  <span>{{ data.item.status }}</span>
                </b-badge>
                <b-badge
                  v-else-if="data.item.status == 'Mise à jour en cours'"
                  pill
                  :variant="`light-warning`"
                  class="text-capitalize"
                >
                  <span>{{ data.item.status }}</span>
                </b-badge>
                <b-badge
                  v-else-if="data.item.status == 'Désactivée'"
                  pill
                  :variant="`light-danger`"
                  class="text-capitalize"
                >
                  <span>{{ data.item.status }}</span>
                </b-badge>
              </template>

              <template #cell(date)="data">
                <b-badge
                  v-if="data.item.etat === 1"
                  pill
                  :variant="`light-success`"
                  class="text-capitalize"
                >
                  <span>Réponse apportée</span>
                </b-badge>
                <b-badge
                  v-else-if="data.item.etat === 0"
                  pill
                  :variant="`light-warning`"
                  class="text-capitalize"
                >
                  <span>Réponse à donner sous {{ 24 - Math.round(((new Date())/1000 - data.item.date)/3600) }}h</span>
                </b-badge>
              </template>


              <template #cell(action)="row">
                <b-button
                  v-if="row.item.etat === 0"
                  @click="row.toggleDetails"
                  variant="outline-primary"
                  size="sm"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="14"
                    class="text-body"
                  />
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="text-center">
                    <b-col sm="12" lg="12" md="12">
                      <b-row class="mb-2">
                        <b-col sm="12"><b>Détail</b></b-col>
                        <b-col sm="6" class="text-left">
                          <b-card class="text-center">
                            <b-col v-for="media in row.item.media" sm="6">
                              <b-embed
                                type="iframe"
                                :src="`https://app2.oscar-black.com/uploads/visuels/${media}`"
                                allowfullscreen
                                class="rounded mb-50"
                              />
                            </b-col>
                          </b-card>
                        </b-col>
                        <b-col sm="6" class="text-left">
                          <b-card class="text-center">
                            <user-view-user-message :user-data="row.item.iduser" />
                          </b-card>
                        </b-col>
                        <b-col sm="12" class="text-center">
                          <b-button
                            @click="reponseApportee(row.item.id)"
                            v-if="row.item.etat === 0"
                            variant="outline-success"
                            size="md"
                          >
                            Réponse apportée
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>

                  </b-row>
                </b-card>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </section>
    </b-col>
  </b-row>
</template>

<script>
import axios from '@axios'
import {
  BBadge, BDropdown, BDropdownItem, BTable, BCard, BButton, BCardBody, BRow, BCol, VBToggle, BCarousel, BCarouselSlide, BLink, BAvatar, BImg, BEmbed,
} from 'bootstrap-vue'
import UserViewUserMessage from '@/views/admin/users-view/UserViewUserMessage.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardBody,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BLink,
    BAvatar,
    BImg,
    BEmbed,
    UserViewUserMessage,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      likedUsers: [
        /* eslint-disable global-require */
        { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
      ],
      campagnes: [],
      fields: [],
      design: '',
      audience: '',
      ready: false,
      edit: null,
      designsOption: [],
      audiencesOption: [],
      budget: 10,
      name: '',
      categories: [
        { category: 'Fashion', icon: 'WatchIcon' },
        { category: 'Food', icon: 'ShoppingCartIcon' },
        { category: 'Gaming', icon: 'CommandIcon' },
        { category: 'Quote', icon: 'HashIcon' },
        { category: 'Video', icon: 'VideoIcon' },
      ],
    }
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/demandesadmin')
      .then(response => {
        this.campagnes = response.data
        this.fields = [
          { key: 'client', label: 'Client' },
          { key: 'objet', label: 'Objet' },
          { key: 'message', label: 'Message' },
          { key: 'drives', label: 'Medias associés' },
          { key: 'date', label: 'Etat' },
          { key: 'action', label: 'Action' }
        ]
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
  methods: {
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    lancer(val) {
      const data = {
        campagneid: val,
      }
      axios
        .post('https://app2.oscar-black.com/api/gestion/campagne/lance', data)
        .then(response => {
          this.campagnes = response.data
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    },
    editEnCours(val) {
      this.edit = val
    },
    audiencess(val) {
      this.audiences = val
    },
    reponseApportee(id) {
      const data = {
        demandeid: id
      }
      axios
        .post('https://app2.oscar-black.com/api/demande/done', data)
        .then(response => {
          this.campagnes = response.data
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/audience', { data: req })
            .then(response => {
              this.designs = response.data
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your ad has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {})
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your ad is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
