<template>
  <b-card title="Messages envoyés / reçus" style="height:900px;overflow:scroll;">
    <section id="apercu">
      <b-card v-if="ready" id="scrollable_div" style="width:100%; margin-right:auto;margin-left:auto;max-height:80vh;overflow:scroll;scroll-behavior: smooth;border:1px solid black;">
        <div v-if="messages != []" v-for="message in messages" :key="message.id">

          <!-- MESSAGE ENVOYE PAR LE CLIENT -->
          <div class="mb-2" v-if="message.sent === 1" style="text-align:right;">
            <div style="background-color:#E4B3D3; display:inline-block;min-width:50%; max-width:80%; padding-right:10px; padding-left:10px; padding-top:2px; padding-bottom:2px; border-radius: 10px;">
              <video
                style="width:100%;"
                v-if="message.file != null && !message.file.includes('vidyard') && message.file !== ''"
                controls>
                <source
                :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
                class="rounded mb-50"
                >
              </video>
            <b-embed
            v-else-if="message.file != null && message.file.includes('vidyard') && message.file !== ''"
            type="iframe"
            :src="`${message.file}`"
            allowfullscreen
            class="rounded mb-50"
            />
            <b-card-text>
              <span class="mb-2" v-if="message.file != null && message.file.includes('vidyard')">Lien du message : <a target="_blank ":href="message.file">cliquez-ici</a></span>
              <span v-if="message.message != ''" class="mt-1" v-html="message.message"></span>
            </b-card-text>
          </div>
          <div style="font-style: italic; font-size:0.8rem;">
            Le {{ (new Date(message.date*1000)).getDate() }}/{{ (new Date(message.date*1000)).getMonth() + 1 }}/{{ (new Date(message.date*1000)).getFullYear() }} à {{ (new Date(message.date*1000)).getHours() }}:{{ (new Date(message.date*1000)).getMinutes() }}
          </div>
        </div>

        <!-- MESSAGE ENVOYE PAR CHARGE DE COMPTE -->
        <div class="mb-2" v-else>
          <div style="background-color:#29235C; display:inline-block;min-width:50%; max-width:80%;padding-right:10px; padding-left:10px; padding-top:2px; padding-bottom:2px; border-radius: 10px;">
            <video
              style="width:100%;"
              v-if="message.file != null && !message.file.includes('vidyard') && message.file !== ''"
              controls>
              <source
              :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
              class="rounded mb-50"
              >
            </video>
          <b-embed
          v-else-if="message.file != null && message.file.includes('vidyard') && message.file !== ''"
          type="iframe"
          :src="`${message.file}`"
          allowfullscreen
          class="rounded mb-50"
          />
          <b-card-text style="color:#fff;">
            <span v-if="message.file != null && message.file.includes('vidyard')">Lien du message : <a style="color:white;" target="_blank ":href="message.file">cliquez-ici</a></span>
            <span v-if="message.message != ''" class="mt-1" v-html="message.message"></span>
          </b-card-text>
        </div>
        <div style="font-style: italic; font-size:0.8rem;">
          {{ message.who }} : le {{ (new Date(message.date*1000)).getDate() }}/{{ (new Date(message.date*1000)).getMonth() + 1 }}/{{ (new Date(message.date*1000)).getFullYear() }} à {{ (new Date(message.date*1000)).getHours() }}:{{ (new Date(message.date*1000)).getMinutes() }}
        </div>
      </div>

    </div>
  </b-card>
  <hr class="mb-2">
  <b-container>
    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="6"
        xl="6"
      >
        <el-tiptap
          placeholder="Votre message..."
          lang="fr"
          v-model="text"
          :extensions="extensions"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
        lg="6"
        xl="6"
      >
        <b-form-file
          class="mt-2"
          size="sm"
          v-model="file"
          placeholder="Vous pouvez accompagner votre message d'une vidéo (format .mp4)"
          accept="video/mp4"
        />
        <b-input-group
          size="sm"
          prepend="OU D'UN "
        >
          <b-form-input
            v-model="vidyard"
            placeholder="lien Vidyard"
          />
        </b-input-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        xl="12"
      >
        <b-button
        @click="formSubmitted()"
        style="width:100%"
        v-if="encours === null"
        variant="primary"
        class="mt-2"
        >
        Envoyer
        </b-button>

        <b-button
        v-if="encours != null"
        variant="primary"
        disabled
        >

          <b-spinner small type="grow"></b-spinner>
          En cours...
        </b-button>
      </b-col>
    </b-row>
  </b-container>

</section>
</b-card>
</template>

<script>

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import axios from '@axios'
import {
  BCard, BRow, BCol, BButton, BContainer, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormFile, BCardText, BSpinner, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
} from 'element-tiptap';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BContainer,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormFile,
    BCardText,
    BSpinner,
    BInputGroup,
    BFormInput,
    ToastificationContent,
    quillEditor,
  },
  props: {
    userData: {
      type: Number,
      required: true,
    },
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      editorOption: {
        theme: 'snow',
        placeholder: "Votre message",
        modules: {
          toolbar: '#toolbar',
        },
      },
      text: '',
      vidyard: '',
      messages: [],
      ready: false,
      encours: null,
      edit: null,
      file: null,
      likedUsers: [
        /* eslint-disable global-require */
        { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
      ],
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // render command-button in bubble menu.
        new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Image(),
      ],
    }
  },
  methods: {
    scroll() {
      var objDiv = document.getElementById("scrollable_div");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    formSubmitted() {
      this.encours = 'hey'
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('id', this.userData)
      formData.append('vidyard', this.vidyard)
      formData.append('message', this.text)
      this.vidyard = ''
      this.file = null
      this.text = ''

      axios
      .post('https://app2.oscar-black.com/api/messageadmin', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        this.encours = null
        this.messages = response.data
        setTimeout(() => {
          this.scroll()
        }, 1500)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Votre message a bien été envoyé !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        this.encours = null
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CrossIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  created() {
    axios
    .get(`https://app2.oscar-black.com/api/messageadmin/${this.userData}`)
      .then(response => {
        this.messages = response.data
        setTimeout(() => {
          this.scroll()
        }, 1500)
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
    },
    watch: {
      vidyard() {
        this.file = null
      },
    },
  }
  </script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }

  .custom-file-input:lang(fr) ~ .custom-file-label::after {
    content: 'Télécharger';
  }
  </style>
